import gsap from 'gsap';
import {Swiper, Navigation} from 'swiper';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

import Globe from 'globe.gl';

class AboutTeam{
	constructor() {
		this.data = {    
		};
		this.init();
	}
  
	init() { 		
    this.initSlider();

    ScrollTrigger.create({
      trigger: document.querySelector('.globe'),
      start: "top bottom",      
      onEnter: () => {
        this.startGlobe();
      },                
    });        
	}    

    initSlider(){
      const swiper = new Swiper('.team .swiper', {
        slidesPerView: 4,
        speed: 1000,
        navigation: {
          nextEl: '.team .next',
          prevEl: '.team .prev',
        },
        spaceBetween: 10,
        modules: [Navigation],        
        loop: true,        
        breakpoints: {          
          0: {
            spaceBetween: 0,
            slidesPerView: 1.5,
          },                          
          640: {
            slidesPerView: 1.5,
          },
          768: {
            spaceBetween: 10,
            slidesPerView: 2,
          },
          1024: {
            spaceBetween: 10,
            slidesPerView: 3,
          },
          1380: {
            spaceBetween: 10,
            slidesPerView: 4,
          }
        }
      });
    }

    startGlobe(){   

      var request = new XMLHttpRequest();
      request.open("GET", "/wp-content/themes/PG/assets/public/css/PoppinsSemiBold_Regular.json", false);
      request.send(null)
      var labelfont = JSON.parse(request.responseText);
      console.log(labelfont);      

      if(document.querySelector('.globe')){
        let gData = JSON.parse(document.querySelector('.globe').getAttribute('data-locations'));
    
        const myGlobe = Globe();
        myGlobe(document.querySelector('.globe'))
          // .globeImageUrl('//unpkg.com/three-globe/example/img/earth-dark.jpg')
          // .globeImageUrl('/wp-content/themes/pg/assets/public/images/dots.jpg')
          .globeImageUrl('https://staging.pubgenius.io/wp-content/themes/PG/assets/public/images/dotsgreen.jpg')        
          // .globeImageUrl('/wp-content/themes/PG/assets/public/images/dotsgreen.jpg')        
          // .bumpImageUrl('//unpkg.com/three-globe/example/img/earth-topology.png')
          .labelsData(gData)
          .labelLat('lat')
          .labelLng('lng')
          .labelText('name')
          .labelColor('color')
          .labelSize(2)
          .labelDotOrientation(() => 'top')
          .labelDotRadius(1)
          .labelResolution(2)
          // .labelRotation(90)
          // .labelIncludeDot
          .labelAltitude(0.03)
          .labelTypeFace(labelfont)
          .labelsTransitionDuration(1000)
          .backgroundColor('rgba(0,0,0,0)')
          .showAtmosphere(true)
          .atmosphereColor('#391EDB')
          
        myGlobe.controls().autoRotate = true;
        myGlobe.controls().autoRotateSpeed = 1;
        myGlobe.controls().enableZoom = false;
        // myGlobe.controls().maxZoom = 1;
        // myGlobe.controls().minZoom = 1.1;
      }
    }
}


export default AboutTeam;